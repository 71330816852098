.featured{
    .slider{
        .slick-slider{
            overflow:hidden;
            .slick-track{
                display: flex;
                gap: 10px;
                .slick-slide{
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
}
