@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes anim {
  0%{
      transform: translateY(-500px);
  }
  100%{
      transform: translateY(0px);
  }
}
@keyframes anim2 {
  0%{
    transform: translateY(0px);
  }
  100%{
    transform: translateY(-500px);
  }
}
body{
  background-color:#F2F2F2;
  overflow-x: hidden;
}

.navbar > .active {
  display: flex;
  animation: anim .8s ease-in-out 
}
/* .navbar > .inactive {
  animation: anim2 .8s ease-in-out;
} */

.cool-link {
  display:flex;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
}

.cool-link::after {
  content: '';
  display: flex;
  width: 0;
  height: 2px;
  background: #F24949;
  transition: width .3s;
}

.cool-link:hover::after {
  width: 100%;
  color: #F24949;
  transition: width .3s;
}